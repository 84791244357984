import Layout from '~/components/layout/Layout';
import type { LoaderFunction } from '@remix-run/node';
import { json } from '@remix-run/node';
import { fetchStrapi } from '~/lib/server/api.server';
import { CreditCardCategoriesComponent, CreditCardCategory, type BlockComponent, type LandingPage } from '@cardo/types';
import type { MetaFunction } from '@remix-run/react';
import { useLoaderData } from '@remix-run/react';
import { Blocks, Block } from '~/components/blocks/BlockManager';
import {
  generateMetaFromSeoComponent,
  generateOgUrlMetaDescriptor,
} from '~/lib/meta';
import classNames from 'classnames';
import { generateAppUrl } from '~/lib/utils';
import { promiseHash } from 'remix-utils/promise';

export function headers({ loaderHeaders }: { loaderHeaders: Headers }) {
  return {
    'cache-control': loaderHeaders.get('cache-control'),
  };
}

const heroBlockCategorySlugs = ['best-cards', 'travel', 'cash-back', 'business', 'building-credit', 'balance-transfer'];

export const loader: LoaderFunction = async () => {
  const landingPageReq = fetchStrapi<LandingPage>('/landing-page', {
    populate: 'deep,5',
  });

  const categoriesReq = fetchStrapi<CreditCardCategory[]>('/credit-card-categories', {
    populate: ['thumbnail']
  })

  const { categoriesRes, landingPageRes } = await promiseHash({
    categoriesRes: categoriesReq,
    landingPageRes: landingPageReq,
  });

  const categories = categoriesRes.data;
  const landingPage = landingPageRes.data;

  let syntheticHeroBlock: CreditCardCategoriesComponent | null = null;

  if (categories && categories.length > 0 && !categoriesRes.error) {
    const validCategories = heroBlockCategorySlugs
      .map(slug => categories.find(category => category.attributes.slug === slug))
      .filter(category => category !== undefined);

    syntheticHeroBlock = {
      id: 1,
      __component: 'client.credit-card-categories',
      heading: '',
      subheading: '',
      categories: validCategories.map((category, idx) => ({
        id: idx,
        __component: 'client.credit-card-category',
        category: {
          data: category
        }
      }))
    }
  }

  return json({ syntheticHeroBlock, landingPage }, {
    headers: {
      'cache-control':
        'max-age=1, s-maxage=604800, stale-while-revalidate=31536000',
    },
  });
};

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  const { landingPage } = data;
  return [
    ...generateMetaFromSeoComponent(landingPage?.attributes.seo),
    generateOgUrlMetaDescriptor(location),
    {
      tagName: 'link',
      rel: 'canonical',
      href: generateAppUrl(location.pathname),
    },
    { title: 'Cardonomics - Home' },
  ];
};

type HeroProps = {
  heading: string;
  subheading?: string;
  block?: BlockComponent | null;
};

function Hero({ heading, subheading, block }: HeroProps) {
  return (
    <div className="flex h-full w-full flex-grow flex-col items-center justify-between py-12 sm:py-20">
      <h1
        className={classNames(
          'text-center text-4xl sm:text-left md:text-5xl lg:text-6xl',
          subheading && 'mb-4',
          !subheading && 'mb-16'
        )}
      >
        {heading}
      </h1>
      {subheading && (
        <p className="mb-8 w-4/5 text-center sm:w-1/2 sm:text-xl">
          {subheading}
        </p>
      )}
      {block && (
        <div className="flex w-full flex-grow items-center justify-center">
          <Block block={block} />
        </div>
      )}
    </div>
  );
}

export default function Index() {
  const { syntheticHeroBlock, landingPage } = useLoaderData<typeof loader>();

  const heroBlock =
    landingPage?.attributes.hero && landingPage?.attributes.hero?.length > 0
      ? landingPage.attributes.hero[0]
      : syntheticHeroBlock;

  return (
    <Layout
      hero={
        landingPage?.attributes.heading ? (
          <Hero
            heading={landingPage?.attributes.heading}
            subheading={landingPage?.attributes.subheading}
            block={heroBlock}
          />
        ) : null
      }
      heroContainerClassName="h-fit"
    >
      <div className="mx-auto flex w-full flex-col space-y-12 2xl:w-4/5">
        {landingPage?.attributes.blocks && landingPage?.attributes.blocks.length > 0 && (
          <Blocks blocks={landingPage?.attributes.blocks} />
        )}
      </div>
    </Layout>
  );
}
